import axios from 'axios'

export default async function postFormToYandexGpt(payload: {
  requestText: string
  instructionText: string
  temperature: number
}): Promise<any> {
  const { data } = await axios.post('/api/v1/sandbox/yandex-gpt', {
    ...payload,
  })
  return data
}
